import React from 'react';

class PageNotFound extends React.Component {


  render() {
    return (
      <div className='PageNoteFound'>
        <h1>Page not found</h1>
      </div>
    )
  }
}

export default PageNotFound;